.projects-div {

  @keyframes slideDown {
    0% {
      position: absolute;
      top: -.5rem;
      opacity: 0;
    }

    100% {
      position: absolute;
      top: 35%;
      opacity: 1;
    }
  }
  margin-bottom: 50px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  .image-holder {
    height: 200px;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .hover-div {
    position: absolute;
    opacity: 0;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-name: slideDown;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 350px;
    .yellow {
      background-color: #697dff;
    }
    .green {
      background-color: #5bdaff;
    }
    .hover-btn {
      p {
        margin: 0;
      }
      cursor: pointer;
      // height: 30px;
      // border-radius: 5px;
      // padding: 5px 10px;
      // display: flex;
      // align-items: center;
      margin: 5px 15px;
      // border: 2px solid white;
      padding:0.3em 1.2em;
      // margin:0 0.1em 0.1em 0;
      border:0.16em solid rgba(255,255,255,0);
      border-radius:2em;
      box-sizing: border-box;
      text-decoration:none;
      font-family:'Roboto',sans-serif;
      font-weight:800;
      color:#fff;
      text-shadow: 0 0.04em 0.04em rgba(0,0,0,0.35);
      text-align:center;
      transition: all 0.2s;
      &:hover {
        border-color: rgba(255,255,255,1);
      }
      a {
        color: #fff;
        text-decoration: none;
      }
    }
  }
}
a, a:visited {
  color: #282c34;
}
.tech-holder {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  .tech-btn {
    margin: 10px 5px;
    border-radius: 20px;
    background-color: #282c34;
    color: #fff;
    padding: 10px 15px;
  }
}
.single-project {
   p {
     margin: 0;
     margin-top: 5px;
   }
  .curtain-div {
    border-radius: 10px;
    color: #282c34;
    background-color: black;
    opacity: 0.5;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
  }
  position: relative;
  margin: 20px;
  padding: 10px;
  border-radius: 10px;
  color: #282c34;
  background-color: #fff;
  flex-direction: column;
  display: flex;
  flex: 1 0 30%;
  align-items: center;
  justify-content: center;
  width: 100%;
}


.single-modal {
  overflow: hidden;
  border-radius: 5px;
  color: #282c34;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 600px;
  width: 600px;
  z-index: 102;
  background-color: white;

  .info-holder {
    .modal-title {
      margin: 0 0 15px 0;
      font-size: 25px;
      padding-bottom: 4px;
      border-bottom: 1px solid black;
    }
    padding: 20px;
  }
  .image-holder {
    height: 300px;
    width: 100%;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

.modal-curtain {
  position: fixed;
  top: 0; bottom: 0; left: 0; right: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
  opacity: .5;
  z-index: 101;
}

@media screen and (max-width: 950px) {
  .projects-div {
    flex-direction: column;
  }
}
