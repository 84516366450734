.footer {
  background-color: #252627;
  width: 100%;
  height: 100px;
  border-top: 2px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  line-height: 100px;
  font-size: 20px;
  // position: absolute;
  // bottom: 0;
}
.media-links {
  margin-left: 20px;
  a {
    font-size: 30px;
    color: white;
    padding: 0 10px;
    text-decoration: none;
  }
}
