@import url(https://fonts.googleapis.com/css?family=Open+Sans:700,800&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box !important;
}
html, body {
  height: 100%;
}

.App {
  min-height: 100vh;
  background-color: #282c34;
  color: white;
}

.fill {
  background: none;
  border: 2px solid;
  font: inherit;
  line-height: 1;
  margin: 0.5em;
  padding: 1em 2em;
  border-color: #697dff;
  color: #697dff;
  -webkit-transition: 0.25s;
  transition: 0.25s; }

.fill:hover, .fill:focus {
  color: white;
  box-shadow: inset 0 0 0 2em #697dff; }

.dashboard-holder {
  position: relative; }
  .dashboard-holder .name-holder {
    position: relative;
    height: 100px; }
    .dashboard-holder .name-holder .first, .dashboard-holder .name-holder .last {
      font-size: 4.4rem;
      position: absolute;
      opacity: 0;
      top: 5rem;
      -webkit-animation-duration: 2s;
              animation-duration: 2s;
      -webkit-animation-fill-mode: forwards;
              animation-fill-mode: forwards; }
    .dashboard-holder .name-holder .first {
      -webkit-animation-name: topFadeOut;
              animation-name: topFadeOut;
      color: white; }
    .dashboard-holder .name-holder .last {
      left: 14rem;
      -webkit-animation-name: bottomFadeOut;
              animation-name: bottomFadeOut;
      color: #5bdaff; }
  .dashboard-holder .info-holder {
    margin-top: -20px;
    position: relative;
    font-size: 20px;
    opacity: 0;
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
    -webkit-animation-name: fadeIn;
            animation-name: fadeIn;
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards; }
  .dashboard-holder .body-holder {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    margin: 50px 0 0 0;
    font-size: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 20%; }
  .dashboard-holder .life {
    color: #fff;
    font-size: 92px;
    font-family: 'ubuntu';
    text-transform: uppercase;
    font-weight: 700;
    font-family: 'Josefin Sans',sans-serif;
    background: -webkit-gradient(linear, left top, right top, color-stop(10%, #697dff), color-stop(50%, #fff), color-stop(60%, #5bdaff));
    background: linear-gradient(to right, #697dff 10%, #fff 50%, #5bdaff 60%);
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-animation: textclip 1.5s linear infinite;
            animation: textclip 1.5s linear infinite;
    display: inline-block; }
  .dashboard-holder .button-holder {
    margin: 0 auto;
    width: 400px;
    display: flex; }
    .dashboard-holder .button-holder a, .dashboard-holder .button-holder a:hover {
      text-decoration: none; }
    .dashboard-holder .button-holder .dash-btn {
      -webkit-transition: 0.5s;
      transition: 0.5s;
      background: none;
      font: inherit;
      line-height: 1;
      border-radius: 40px;
      margin: 5px;
      font-size: 20px;
      padding: 1em 2em; }
    .dashboard-holder .button-holder .blue {
      color: #5bdaff;
      border: 2px solid #5bdaff; }
      .dashboard-holder .button-holder .blue:hover, .dashboard-holder .button-holder .blue:focus {
        color: white;
        box-shadow: inset 0 0 0 2em #5bdaff; }
    .dashboard-holder .button-holder .purple {
      color: #697dff;
      border: 2px solid #697dff; }
      .dashboard-holder .button-holder .purple:hover, .dashboard-holder .button-holder .purple:focus {
        color: white;
        box-shadow: inset 0 0 0 2em #697dff; }

@-webkit-keyframes textclip {
  to {
    background-position: 200% center; } }

@keyframes textclip {
  to {
    background-position: 200% center; } }

.line {
  position: absolute;
  width: 1px;
  height: 100%;
  top: 0;
  overflow: hidden; }

.line::after {
  content: '';
  display: block;
  position: absolute;
  height: 15vh;
  width: 100%;
  top: -50%;
  left: 0;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), color-stop(75%, #ffffff), to(#ffffff));
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 75%, #ffffff 100%);
  -webkit-animation: run 5s 0s infinite;
          animation: run 5s 0s infinite;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
          animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97); }

.line:nth-child(1) {
  margin-left: -25%; }

.line:nth-child(1)::after {
  -webkit-animation-delay: 2s;
          animation-delay: 2s; }

.line:nth-child(3) {
  margin-left: 25%; }

.line:nth-child(3)::after {
  -webkit-animation-delay: 2.5s;
          animation-delay: 2.5s; }

@-webkit-keyframes run {
  0% {
    top: -50%; }
  100% {
    top: 110%; } }

@keyframes run {
  0% {
    top: -50%; }
  100% {
    top: 110%; } }

@-webkit-keyframes topFadeOut {
  0% {
    position: absolute;
    top: -3rem;
    opacity: 0; }
  100% {
    position: absolute;
    top: 25%;
    opacity: 1; } }

@keyframes topFadeOut {
  0% {
    position: absolute;
    top: -3rem;
    opacity: 0; }
  100% {
    position: absolute;
    top: 25%;
    opacity: 1; } }

@-webkit-keyframes bottomFadeOut {
  0% {
    position: absolute;
    bottom: 0;
    opacity: 0; }
  100% {
    position: absolute;
    top: 25%;
    opacity: 1; } }

@keyframes bottomFadeOut {
  0% {
    position: absolute;
    bottom: 0;
    opacity: 0; }
  100% {
    position: absolute;
    top: 25%;
    opacity: 1; } }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  45% {
    opacity: .5; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  45% {
    opacity: .5; }
  100% {
    opacity: 1; } }

.about-holder {
  line-height: 30px; }
  .about-holder .about-top {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center; }
  .about-holder .about-picture {
    margin-right: 20px;
    border-radius: 5px;
    height: 400px;
    width: 400px;
    overflow: hidden;
    flex: 1 1 50%; }
    .about-holder .about-picture img {
      height: 100%;
      width: 100%;
      object-fit: cover; }
  .about-holder .about-paragraph {
    flex: 1 1 50%; }
    .about-holder .about-paragraph h2 {
      font-size: 30px;
      margin-bottom: 30px; }
  .about-holder .about-bottom {
    display: inline-block; }

.form {
  /* necessary to give position: relative to parent. */
  margin: 10px;
  display: flex;
  justify-content: center;
  width: 600px;
  margin: 0 auto;
  flex-wrap: wrap; }
  .form :focus {
    outline: none; }
  .form .col-3 {
    border-radius: 5px;
    z-index: 0;
    background-color: #fff;
    font: 14px "Lato", Arial, sans-serif;
    float: left;
    width: 27.33%;
    margin: 15px;
    position: relative;
    flex: 1 1 50%; }
  .form input[type="text"], .form textarea {
    border-radius: 5px;
    font: 15px/24px "Lato", Arial, sans-serif;
    color: #333;
    width: 100%;
    box-sizing: border-box;
    letter-spacing: 1px;
    z-index: 2; }
  .form .effect-20 {
    border: none;
    padding: 10px 14px;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    background: transparent; }
  .form .effect-20 ~ .focus-border:before,
  .form .effect-20 ~ .focus-border:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: #3399FF;
    -webkit-transition: 0.3s;
    transition: 0.3s; }
  .form .effect-20 ~ .focus-border:after {
    top: auto;
    bottom: 0;
    left: auto;
    right: 0; }
  .form .effect-20 ~ .focus-border i:before,
  .form .effect-20 ~ .focus-border i:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: 0;
    background-color: #3399FF;
    -webkit-transition: 0.4s;
    transition: 0.4s; }
  .form .effect-20 ~ .focus-border i:after {
    left: auto;
    right: 0;
    top: auto;
    bottom: 0; }
  .form .effect-20:focus ~ .focus-border:before,
  .form .effect-20:focus ~ .focus-border:after,
  .form .has-content.effect-20 ~ .focus-border:before,
  .form .has-content.effect-20 ~ .focus-border:after {
    width: 100%;
    -webkit-transition: 0.3s;
    transition: 0.3s; }
  .form .effect-20:focus ~ .focus-border i:before,
  .form .effect-20:focus ~ .focus-border i:after,
  .form .has-content.effect-20 ~ .focus-border i:before,
  .form .has-content.effect-20 ~ .focus-border i:after {
    height: 100%;
    -webkit-transition: 0.4s;
    transition: 0.4s; }
  .form .effect-20 ~ label {
    position: absolute;
    left: 14px;
    width: 100%;
    top: 13px;
    color: black;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    z-index: 0;
    letter-spacing: 0.5px; }
  .form .effect-20:focus ~ label, .form .has-content.effect-20 ~ label {
    top: -18px;
    left: 0;
    font-size: 12px;
    color: #3399FF;
    -webkit-transition: 0.3s;
    transition: 0.3s; }
  .form .inputs, .form .textarea-div {
    display: flex;
    width: 100%; }
  .form .add-project {
    width: 100%; }
    .form .add-project textarea, .form .add-project input {
      margin: 5px 0;
      padding: 10px; }
  .form textarea {
    height: 100px;
    flex: 1 1 100%;
    resize: none; }

.button {
  background-color: #697dff;
  cursor: pointer;
  margin: 5px 15px;
  padding: 0.3em 1.2em;
  border: 0.16em solid rgba(255, 255, 255, 0);
  border-radius: 2em;
  box-sizing: border-box;
  text-decoration: none;
  font-family: 'Roboto',sans-serif;
  font-weight: 800;
  color: #fff;
  text-shadow: 0 0.04em 0.04em rgba(0, 0, 0, 0.35);
  text-align: center;
  -webkit-transition: all 0.2s;
  transition: all 0.2s; }
  .button p {
    margin: 0; }
  .button:hover {
    border-color: white; }
  .button a {
    color: #fff;
    text-decoration: none; }

.contact-holder {
  min-height: 500px; }
  .contact-holder .email-sent {
    text-align: center;
    font-size: 30px;
    width: 550px;
    margin: 0 auto;
    color: #282c34;
    border-radius: 5px;
    padding: 30px 20px;
    background: #fff; }
    .contact-holder .email-sent p {
      margin: 10px; }

.contact-div {
  margin: 70px auto;
  width: 675px;
  text-align: center; }
  .contact-div h2 {
    margin: 0;
    font-size: 55px; }
  .contact-div p {
    font-size: 20px; }
  .contact-div .email-link {
    color: #5bdaff; }
    .contact-div .email-link a:visited {
      color: #5bdaff; }

.projects-div {
  margin-bottom: 50px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%; }

@-webkit-keyframes slideDown {
  0% {
    position: absolute;
    top: -.5rem;
    opacity: 0; }
  100% {
    position: absolute;
    top: 35%;
    opacity: 1; } }

@keyframes slideDown {
  0% {
    position: absolute;
    top: -.5rem;
    opacity: 0; }
  100% {
    position: absolute;
    top: 35%;
    opacity: 1; } }
  .projects-div .image-holder {
    height: 200px;
    width: 100%;
    border-radius: 10px;
    overflow: hidden; }
    .projects-div .image-holder img {
      height: 100%;
      width: 100%;
      object-fit: cover; }
  .projects-div .hover-div {
    position: absolute;
    opacity: 0;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-name: slideDown;
            animation-name: slideDown;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 350px; }
    .projects-div .hover-div .yellow {
      background-color: #697dff; }
    .projects-div .hover-div .green {
      background-color: #5bdaff; }
    .projects-div .hover-div .hover-btn {
      cursor: pointer;
      margin: 5px 15px;
      padding: 0.3em 1.2em;
      border: 0.16em solid rgba(255, 255, 255, 0);
      border-radius: 2em;
      box-sizing: border-box;
      text-decoration: none;
      font-family: 'Roboto',sans-serif;
      font-weight: 800;
      color: #fff;
      text-shadow: 0 0.04em 0.04em rgba(0, 0, 0, 0.35);
      text-align: center;
      -webkit-transition: all 0.2s;
      transition: all 0.2s; }
      .projects-div .hover-div .hover-btn p {
        margin: 0; }
      .projects-div .hover-div .hover-btn:hover {
        border-color: white; }
      .projects-div .hover-div .hover-btn a {
        color: #fff;
        text-decoration: none; }

a, a:visited {
  color: #282c34; }

.tech-holder {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0; }
  .tech-holder .tech-btn {
    margin: 10px 5px;
    border-radius: 20px;
    background-color: #282c34;
    color: #fff;
    padding: 10px 15px; }

.single-project {
  position: relative;
  margin: 20px;
  padding: 10px;
  border-radius: 10px;
  color: #282c34;
  background-color: #fff;
  flex-direction: column;
  display: flex;
  flex: 1 0 30%;
  align-items: center;
  justify-content: center;
  width: 100%; }
  .single-project p {
    margin: 0;
    margin-top: 5px; }
  .single-project .curtain-div {
    border-radius: 10px;
    color: #282c34;
    background-color: black;
    opacity: 0.5;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute; }

.single-modal {
  overflow: hidden;
  border-radius: 5px;
  color: #282c34;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  height: 600px;
  width: 600px;
  z-index: 102;
  background-color: white; }
  .single-modal .info-holder {
    padding: 20px; }
    .single-modal .info-holder .modal-title {
      margin: 0 0 15px 0;
      font-size: 25px;
      padding-bottom: 4px;
      border-bottom: 1px solid black; }
  .single-modal .image-holder {
    height: 300px;
    width: 100%; }
    .single-modal .image-holder img {
      height: 100%;
      width: 100%;
      object-fit: cover; }

.modal-curtain {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
  opacity: .5;
  z-index: 101; }

@media screen and (max-width: 950px) {
  .projects-div {
    flex-direction: column; } }

.add-holder {
  min-height: 500px; }

.navbar {
  z-index: 100;
  top: 0;
  width: 100%;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 10px 20px; }
  .navbar .padding {
    padding-bottom: 4px; }
  .navbar a {
    text-decoration: none;
    font-size: 25px;
    margin: 0 20px;
    color: unset; }
  .navbar a:visited {
    color: unset; }
  .navbar img {
    height: 60px;
    width: 60px; }

.home {
  display: flex;
  height: 60px;
  width: 60px;
  align-items: center; }

.active-dash {
  border-radius: 30px;
  background-color: #697dff; }

.active {
  border-bottom: 2px solid #5bdaff; }

.disabled {
  pointer-events: none; }

.footer {
  background-color: #252627;
  width: 100%;
  height: 100px;
  border-top: 2px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  line-height: 100px;
  font-size: 20px; }

.media-links {
  margin-left: 20px; }
  .media-links a {
    font-size: 30px;
    color: white;
    padding: 0 10px;
    text-decoration: none; }

.page-view {
  position: relative;
  min-height: 100vh;
  z-index: 10; }
  .page-view--topbar {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 60px;
    z-index: 80; }
  .page-view--sidebar {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 0;
    z-index: 100;
    background-color: #fff;
    border: 1px solid #eee;
    overflow: hidden; }
  .page-view--main-view {
    margin: 0 auto;
    max-width: 1000px;
    position: relative;
    padding: 0 30px;
    min-height: 100vh; }

