.form {
  :focus{outline: none;}

  .col-3{ border-radius: 5px; z-index: 0; background-color: #fff; font: 14px "Lato", Arial, sans-serif; float: left; width: 27.33%; margin: 15px; position: relative; flex: 1 1 50%;} /* necessary to give position: relative to parent. */
  input[type="text"], textarea { border-radius: 5px; font: 15px/24px "Lato", Arial, sans-serif; color: #333; width: 100%; box-sizing: border-box; letter-spacing: 1px; z-index: 2}

  .effect-20{ border: none; padding: 10px 14px; transition: 0.4s; background: transparent;}
  .effect-20 ~ .focus-border:before,
  .effect-20 ~ .focus-border:after{content: ""; position: absolute; top: 0; left: 0; width: 0; height: 2px; background-color: #3399FF; transition: 0.3s;}
  .effect-20 ~ .focus-border:after{top: auto; bottom: 0; left: auto; right: 0;}
  .effect-20 ~ .focus-border i:before,
  .effect-20 ~ .focus-border i:after{content: ""; position: absolute; top: 0; left: 0; width: 2px; height: 0; background-color: #3399FF; transition: 0.4s;}
  .effect-20 ~ .focus-border i:after{left: auto; right: 0; top: auto; bottom: 0;}
  .effect-20:focus ~ .focus-border:before,
  .effect-20:focus ~ .focus-border:after,
  .has-content.effect-20 ~ .focus-border:before,
  .has-content.effect-20 ~ .focus-border:after{width: 100%; transition: 0.3s;}
  .effect-20:focus ~ .focus-border i:before,
  .effect-20:focus ~ .focus-border i:after,
  .has-content.effect-20 ~ .focus-border i:before,
  .has-content.effect-20 ~ .focus-border i:after{height: 100%; transition: 0.4s;}
  .effect-20 ~ label{ position: absolute; left: 14px; width: 100%; top: 13px; color: black; transition: 0.3s; z-index: 0; letter-spacing: 0.5px; }
  .effect-20:focus ~ label, .has-content.effect-20 ~ label{top: -18px; left: 0; font-size: 12px; color: #3399FF; transition: 0.3s;}

  margin: 10px;
  display: flex;
  justify-content: center;
  width: 600px;
  margin: 0 auto;
  flex-wrap: wrap;
  .inputs, .textarea-div {
    display: flex;
    width: 100%;
  }

  .add-project {
    width: 100%;
    textarea, input {
      margin: 5px 0;
      padding: 10px;
    }
  }
  textarea {
    height: 100px;
    flex: 1 1 100%;
    resize: none;
  }
}
