.navbar {
  // position: fixed;
  z-index: 100;
  top: 0;
  width: 100%;
  // background-color: #788a90;
  color: #fff;
  display: flex;
  align-items: center;
  .padding {
    padding-bottom: 4px;
  }
  a {
    text-decoration: none;
    font-size: 25px;
    margin: 0 20px;
    color: unset;
  }
  a:visited {
    color: unset;
  }
  img {
    height: 60px;
    width: 60px;
  }
  padding: 10px 20px;
}

.home {
  display: flex;
  height: 60px;
  width: 60px;
  align-items: center;
}

.active-dash {
  border-radius: 30px;
  background-color: #697dff;
}

.active {
  // color: #282c34!important;
  border-bottom: 2px solid #5bdaff;
}

.disabled {
  pointer-events: none;
}
