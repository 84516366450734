.about-holder {
  line-height: 30px;
  .about-top {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .about-picture {
    margin-right: 20px;
    border-radius: 5px;
    height: 400px;
    width: 400px;
    overflow: hidden;
    flex: 1 1 50%;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .about-paragraph {
    flex: 1 1 50%;
    h2 {
      font-size: 30px;
      margin-bottom: 30px;
    }
  }

  .about-bottom {
    display: inline-block;
  }
}
