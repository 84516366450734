.contact-holder {
  min-height: 500px;

  .email-sent {
    text-align: center;
    font-size: 30px;
    width: 550px;
    margin: 0 auto;
    color: #282c34;
    border-radius: 5px;
    padding: 30px 20px;
    background: #fff;
    p {
      margin: 10px;
    }
  }
}

.contact-div {
  margin: 70px auto;
  width: 675px;
  text-align: center;
  h2 {
    margin: 0;
    font-size: 55px;
  }
   p {
     font-size: 20px;
   }
   .email-link {
     a:visited {
       color: #5bdaff;
     }
     color: #5bdaff;
   }
}
