.button {
  p {
    margin: 0;
  }
  background-color: #697dff;
  cursor: pointer;
  margin: 5px 15px;
  padding:0.3em 1.2em;
  border:0.16em solid rgba(255,255,255,0);
  border-radius:2em;
  box-sizing: border-box;
  text-decoration:none;
  font-family:'Roboto',sans-serif;
  font-weight:800;
  color:#fff;
  text-shadow: 0 0.04em 0.04em rgba(0,0,0,0.35);
  text-align:center;
  transition: all 0.2s;
  &:hover {
    border-color: rgba(255,255,255,1);
  }
  a {
    color: #fff;
    text-decoration: none;
  }
}
