@import url('https://fonts.googleapis.com/css?family=Open+Sans:700,800&display=swap');

.fill {
  background: none;
  border: 2px solid;
  font: inherit;
  line-height: 1;
  margin: 0.5em;
  padding: 1em 2em;
  border-color: #697dff;
  color: #697dff;
  transition: 0.25s;
}
.fill:hover,.fill:focus {
  color: white;
  box-shadow: inset 0 0 0 2em #697dff;
}


.dashboard-holder {
  position: relative;
  .name-holder {
    position: relative;
    height: 100px;
    .first, .last {
      font-size: 4.4rem;
      position: absolute;
      opacity: 0;
      top: 5rem;
      animation-duration: 2s;
      animation-fill-mode: forwards;
    }
    .first {
      animation-name: topFadeOut;
      color: white;
    }
    .last {
      left: 14rem;
      animation-name: bottomFadeOut;
      color: #5bdaff;
    }
  }

  .info-holder {
    margin-top: -20px;
    position: relative;
    font-size: 20px;
    opacity: 0;
    animation-delay: 1s;
    animation-name: fadeIn;
    animation-duration: 2s;
    animation-fill-mode: forwards;
  }
  .body-holder {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    margin: 50px 0 0 0;
    font-size: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 20%;
  }
  .life {
    color: #fff;
    font-size: 92px;
		font-family: 'ubuntu';
		text-transform: uppercase;
    font-weight: 700;
    font-family: 'Josefin Sans',sans-serif;
    background: linear-gradient(to right,#697dff 10%,#fff 50%,#5bdaff 60%);
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 1.5s linear infinite;
    display: inline-block;
  }
  .button-holder {
    margin: 0 auto;
    width: 400px;
    display: flex;
    a, a:hover {
      text-decoration: none;
    }
    .dash-btn {
      transition: 0.5s;
      background: none;
      font: inherit;
      line-height: 1;
      border-radius: 40px;
      margin: 5px;
      font-size: 20px;
      padding: 1em 2em;

    }
    .blue {
      color: #5bdaff;
      border: 2px solid #5bdaff;
      &:hover,&:focus {
        color: white;
        box-shadow: inset 0 0 0 2em #5bdaff;
      }
    }
    .purple {
      color: #697dff;
      border: 2px solid #697dff;
      &:hover,&:focus {
        color: white;
        box-shadow: inset 0 0 0 2em #697dff;
      }
    }
  }
}

@keyframes textclip {
 to {
  background-position:200% center
 }
}

.lines{
  // position: absolute;
  // top: 0;
  // left: 0;
  // right: 0;
  // height: 100%;
  // margin: auto;
  // width: 100vw;
}
.line {
  position: absolute;
  width: 1px;
  height: 100%;
  top: 0;
  overflow: hidden;
}
.line::after {
  content: '';
  display: block;
  position: absolute;
  height: 15vh;
  width: 100%;
  top: -50%;
  left: 0;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 75%, #ffffff 100%);
  animation: run 5s 0s infinite;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
}
.line:nth-child(1){
  margin-left: -25%;
}
.line:nth-child(1)::after {
  animation-delay: 2s;
}
.line:nth-child(3) {
  margin-left: 25%;
}
.line:nth-child(3)::after{
  animation-delay: 2.5s;
}

@keyframes run {
  0% {
    top: -50%;
  }
  100%{
    top: 110%;
  }
}

@keyframes topFadeOut {
  0% {
    position: absolute;
    top: -3rem;
    opacity: 0;
  }

  100% {
    position: absolute;
    top: 25%;
    opacity: 1;
  }
}

@keyframes bottomFadeOut {
  0% {
    position: absolute;
    bottom: 0;
    opacity: 0;
  }

  100% {
    position: absolute;
    top: 25%;
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  45% {
    opacity: .5;
  }

  100% {
    opacity: 1;
  }
}
