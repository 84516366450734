

$sidebar-width-normal: 0;
$sidebar-width-small: 0;

$navbar-height-normal: 60px;



.page-view {
    position: relative;
    min-height: 100vh;
    z-index: 10;
    // padding-top:$navbar-height-normal;
    // padding-left: 0;


    &--topbar {
        position: fixed;
        left: 0;
        top:0;
        width: 100%;
        height: $navbar-height-normal;
        z-index: 80;
    }
    &--sidebar {
        position: fixed;
        left: 0;
        top: 0;
        height: 100%;
        width: $sidebar-width-normal;
        z-index: 100;
        background-color: #fff;
        border: 1px solid #eee;
        // @include breakpoint(xs) { width: $sidebar-width-small};
        overflow: hidden;
    }

    &--main-view {
      // width: 100%;
      margin: 0 auto;
      max-width: 1000px;
      position: relative;
      padding: 0 30px;
      // margin-top: 69px;
      min-height: 100vh;
    }
}
